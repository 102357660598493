import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/public/layout';
import Newspanel from '../components/public/newsPanel'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
export const query = graphql`
    query($slug: String!, $title: String!) {
        contentfulMultimedia(linkName: {eq: $slug}){
            linkName
            title
            publishDate(formatString: "D MMMM YYYY", locale: "pl")
            author
            content {
                raw
            }
            videoLink
            galleryTag
        }
	allContentfulAsset(filter: { title: { eq: $title } }) {
			edges {
				node {
					file {
						url
					}
				}
			}
		}
	}
	`

const getIFrame = (link, videoTitle) => {
    if (link.includes('www.youtube.com'))
        return (
            <iframe
                src={`https://www.youtube-nocookie.com/embed/${link.split('=').at(-1)}`}
                title={videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                width='100%'
                allowFullScreen
                style={{ aspectRatio: '16 / 9' }}
            />
        )
    else if (link.includes('www.facebook.com'))
        return (
            <iframe
                src={`https://www.facebook.com/plugins/video.php?height=265&href=https%3A%2F%2Fwww.facebook.com%2Fparafia.skoszewy%2Fvideos%2F${link.split('/').at(-1)}%2F&show_text=false&width=560&t=0`}
                width='100%'
                title={videoTitle}
                style={{ aspectRatio: '25 / 12' }}
                scrolling="no"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
            />
        )
    else return null
}
const Media = (props) => {
    const options = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    }
    return (
        <Layout>
            <Helmet title={`${props.data.contentfulMultimedia.title} | Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="left-col">
                    <div className="article-without-photo">
                        <span className='where'>
                            <Link to='/'>Strona Główna</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/multimedia'>Multimedia</Link>
                        </span>
                        <h3>{props.data.contentfulMultimedia.title}</h3>
                        <div className="line">
                            <span>{props.data.contentfulMultimedia.publishDate}</span>
                            <span>Autor: {props.data.contentfulMultimedia.author}</span>
                        </div>
                        {
                            props.data.contentfulMultimedia.videoLink != null ?
                            getIFrame(props.data.contentfulMultimedia.videoLink, null) :
                            props.data.allContentfulAsset.edges.map(edge => {
								return (
									<img src={edge.node.file.url} alt="gallery" width='100%'/>
								)
							})
                        }
                        <div style={{ color: '#111111', textAlign: 'justify' }}>
                            {documentToReactComponents(JSON.parse(props.data.contentfulMultimedia.content.raw), options)}
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <Newspanel currArticle={props.data.contentfulMultimedia.linkName} />
                    {/* <div className="facebook-panel"></div> */}
                </div>
            </div>
        </Layout>
    );
}

export default Media

